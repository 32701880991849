import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginPage.css'; // Ensure styles are in place

function ForgotPassword() {
  const [securityQuestion, setSecurityQuestion] = useState('Please enter your email above to see your security question.');
  const [securityAnswer, setSecurityAnswer] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const navigate = useNavigate();

  // Fetch the security question based on the email entered
  const fetchSecurityQuestion = async () => {
    console.log(emailAddress);
    if (emailAddress) {
      try {
        const response = await fetch(`https://api.tracktb.com/doctors/security-question?emailAddress=${emailAddress}`, {
          method: 'GET',
        });

        console.log(response);

        if (!response.ok) {
          throw new Error('Failed to fetch security question');
        }

        const data = await response.text();
        setSecurityQuestion(data); // Assuming the response contains the security question
      } catch (err) {
        setError('An error occurred. Please try again later.');
      }
    }
  };

  // Step 2: Verify the answer to the security question
  const verifyAnswer = async () => {

    const requestBody = {
      emailAddress,
      securityAnswer,
    };

    try {
      const response = await fetch('https://api.tracktb.com/doctors/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(requestBody).toString(),
      });

      if (!response.ok) {
        throw new Error('Security answer is incorrect');
      }

      setIsAnswerCorrect(true);
      setError('');
    } catch (err) {
      setError('Your answer to the security question is incorrect.');
    }
  };

  // Step 3: Reset the password
  const handleResetPassword = async (e) => {

    const requestBody = {
      emailAddress,
      newPassword,
    };

    e.preventDefault();
    try {
      const response = await fetch('https://api.tracktb.com/doctors/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(requestBody).toString(),
      });

      if (!response.ok) {
        throw new Error('Failed to reset password');
      }

      setMessage('Password has been successfully reset. Redirecting to login page...');
      setError('');
      setTimeout(() => navigate('/login'), 5000); // Redirect after 5 seconds
    } catch (err) {
      setError('Your attempt to reset the password was unsuccessful. Please contact the administrator.');
      setMessage('');
    }
  };

  return (
    <div className="forgot-password-container">
      <form className="login-form" onSubmit={handleResetPassword}>
        <h1>Forgot Password</h1>

        {/* Email Input */}
        <input
          type="text"
          placeholder="Enter your email"
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
          required
        />

        <button className="accept-button" onClick={fetchSecurityQuestion}>Continue</button>

        {/* Display Security Question once email is entered */}
        {securityQuestion && (
          <div className="security-question">
            <p>{securityQuestion}</p>
            <input
              type="text"
              placeholder="Enter your answer"
              value={securityAnswer}
              onChange={(e) => setSecurityAnswer(e.target.value)}
              required
            />
            <button type="button" onClick={verifyAnswer}>
              Verify Answer
            </button>
          </div>
        )}

        {/* If the answer is correct, show the password field */}
        {isAnswerCorrect && (
          <>
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <button type="submit">Reset Password</button>
          </>
        )}

        {/* Success or Error Messages */}
        {message && <div className="success-message">{message}</div>}
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
}

export default ForgotPassword;
