import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../styles/providersList.css';

const ProvidersListPage = () => {
    const [providers, setProviders] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [selectedDoctorId, setSelectedDoctorId] = useState(null);
    const [selectedDoctorDetails, setSelectedDoctorDetails] = useState(null);
    const userRole = localStorage.getItem('userRole');
    const [isDoctorSelected, setIsDoctorSelected] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProvidersData = async () => {
            try {
                const response = await fetch('https://api.tracktb.com/doctors', {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                let doctorsData = await response.json();

                // Filter based on user role
                if (userRole === 'Secondary Provider') {
                    // Secondary providers should only see primary providers
                    doctorsData = doctorsData.filter(doc => doc.role === 'Primary Provider');
                } else if (userRole !== 'Program Admin' && userRole !== 'Super Admin') {
                    // For any other role, clear the list or apply different logic as needed
                    doctorsData = [];
                }

                setProviders(doctorsData);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        fetchProvidersData();
    }, [userRole]);

    const handleFilterChange = (e) => {
        setSearchFilter(e.target.value.toLowerCase());
    };

    const handleSearch = () => {
        const filteredProviders = providers.filter(provider =>
            provider.doctorId.toString().includes(searchFilter) ||
            (provider.emailAddress && provider.emailAddress.toLowerCase().includes(searchFilter))
        );
        setProviders(filteredProviders);
    };

    const handleDoctorClick = async (doctorId) => {
        try {
            const response = await fetch(`https://api.tracktb.com/doctors/${doctorId}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const doctorDetails = await response.json();
            setSelectedDoctorId(doctorId);
            setSelectedDoctorDetails(doctorDetails);
            setIsDoctorSelected(true); 
        } catch (error) {
            console.error("Failed to fetch doctor details:", error);
        }
    };

    const handleCloseDetails = () => {
        setSelectedDoctorId(null);
        setSelectedDoctorDetails(null);
        setIsDoctorSelected(false);
    };

    const handleInviteClick = () => {
        navigate('/invite-member');
    };

    return (
        <div className="providers-page-container">
            <div className={`providers-list-container ${isDoctorSelected ? 'shrink' : ''}`}>
                <div className="providers-list-header">
                    <h2>Providers List</h2>
                    <button className="invite-button" onClick={handleInviteClick}>Invite New Provider</button>
                </div>
                <div>
                    <input
                        className="search-provider"
                        placeholder="Enter identification number or email"
                        value={searchFilter}
                        onChange={handleFilterChange}
                    />
                    <button className="search-button" onClick={handleSearch}>Search</button>
                </div>
                <table className="providers-list-table">
                    <thead>
                        <tr>
                            <th>Identification #</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Specialization</th>
                            <th>Phone Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {providers.map((provider, index) => (
                            <tr key={index} onClick={() => handleDoctorClick(provider.doctorId)} className={provider.doctorId === selectedDoctorId ? 'active-row' : ''}>
                                <td>{provider.doctorId}</td>
                                <td>{provider.emailAddress}</td>
                                <td>{provider.role}</td>
                                <td>{provider.specialization}</td>
                                <td>{provider.phoneNumber}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {selectedDoctorDetails && (
                <div className={`doctor-details-container ${isDoctorSelected ? 'show' : ''}`}>
                    <h3>Doctor Details</h3>
                    <p><strong>Full Name:</strong> {selectedDoctorDetails.fullName}</p>
                    <p><strong>Email:</strong> {selectedDoctorDetails.emailAddress}</p>
                    <p><strong>Phone Number:</strong> {selectedDoctorDetails.phoneNumber}</p>
                    <p><strong>Role:</strong> {selectedDoctorDetails.role}</p>
                    <p><strong>Specialization:</strong> {selectedDoctorDetails.specialization}</p>
                    <p><strong>License Number:</strong> {selectedDoctorDetails.licenseNumber}</p>
                    <p><strong>Bio:</strong> {selectedDoctorDetails.bio}</p>
                    <button onClick={handleCloseDetails} className='close-button'>Close</button>
                </div>
            )}
        </div>
    );
};

export default ProvidersListPage;
