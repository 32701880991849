import React, { useEffect, useState } from "react";
import Modal from "react-modal";

// Set the app element for accessibility
Modal.setAppElement("#root");

const TermsModal = ({ isOpen, onClose, type }) => {
  const [content, setContent] = useState("Loading content...");

  useEffect(() => {
    const filePath = type === "terms" ? "/termsDoctors.txt" : "/privacypolicy.txt";

    fetch(filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to load content.");
        }
        return response.text();
      })
      .then((data) => {
        setContent(data);
      })
      .catch((error) => {
        setContent("Unable to load the content. Please try again later.");
        console.error("Error fetching content:", error);
      });
  }, [type]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background with blur
          backdropFilter: "blur(4px)",
          zIndex: 1000, 
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "500px",
          width: "80%",
          height: "auto",
          maxHeight: "70%",
          overflowY: "auto",
          borderRadius: "10px",
          padding: "20px",
          backgroundColor: "#ffffff",
        },
      }}
    >
      <h2 style={{ textAlign: "center", fontSize: "24px", marginBottom: "10px" }}>
        {type === "terms" ? "Terms and Conditions" : "Privacy Policy"}
      </h2>
      <div
        style={{
          fontSize: "16px",
          lineHeight: "1.6",
          overflowY: "auto",
          maxHeight: "calc(100% - 100px)", // Allow content to scroll if it overflows
        }}
      >
        {content}
      </div>
      <button
        onClick={onClose}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "#72e0c5",
          border: "none",
          color: "white",
          cursor: "pointer",
          borderRadius: "5px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        Close
      </button>
    </Modal>
  );
};

export default TermsModal;