import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "../styles/homePage.css";

const LogsTable = ({ setIsLoading }) => {
    const [logs, setLogs] = useState([]);
    const userRole = localStorage.getItem('userRole');
    const doctorId = localStorage.getItem('doctorId'); // Retrieve doctorId from localStorage
    const navigate = useNavigate();
    console.log('User Role:', userRole);
    console.log('Doctor ID:', doctorId);
    const fetchLogs = async () => {
        setIsLoading(true);
        try {
            const jwtToken = localStorage.getItem('jwtToken');
            let response;

            // Fetch logs based on user role
            if (userRole === 'Primary Provider') {
                // Use doctorId from local storage
                response = await fetch(`https://api.tracktb.com/responses/logs/provider/${doctorId}`, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                });
            } else {
                // Fetch logs for all patients
                response = await fetch('https://api.tracktb.com/responses/logs', {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                });
            }

            if (!response.ok) {
                throw new Error('Failed to fetch logs');
            }

            const data = await response.json();
            setLogs(data);
            console.log('Fetched patient logs from API:', data);
        } catch (error) {
            console.error('Error fetching logs:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchLogs();
        console.log('Updated logs state:', logs);

    }, []);

    const handleRowClick = (patientID) => {
        navigate(`/patient/${patientID}`);
    };

    return (
        <div className="logs-table-container">
            <div style={{ height: '400px', overflowY: 'scroll' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Patient ID</th>
                            <th>Reason</th>
                            <th>Details</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {logs.map((log) => {
                        return (
                            <tr key={log.patientId} onClick={() => handleRowClick(log.patientId)}>
                                <td>{userRole === 'Primary Provider' ? log.patientId : '******'}</td>
                                <td>{log.reason}</td>
                                <td>{log.details}</td>
                                {(userRole === 'Primary Provider' || userRole === 'Secondary Provider') && (
                                    <td>
                                        <ArrowForwardIcon className="arrowIcon" />
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
                </table>
            </div>
        </div>
    );
};

export default LogsTable;