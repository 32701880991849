import React, { useState } from "react";
import "../styles/addNewProvider.css";
import { useNavigate } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react"; // Use QRCodeCanvas instead

const rolePermissions = {
  "Super Admin": ["Program Admin", "Secondary Provider", "Primary Provider"],
  "Program Admin": ["Secondary Provider", "Primary Provider"],
  "Secondary Provider": ["Primary Provider"],
  "Primary Provider": ["Patient"],
};

const AddProviderPage = () => {
  const [members, setMembers] = useState({});
  const userRole = localStorage.getItem("userRole");
  const jwtToken = localStorage.getItem("jwtToken");
  const navigate = useNavigate();
  const [inviteRole, setInviteRole] = useState("");
  const [email, setEmail] = useState("");
  const [patientId, setPatientId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [messengerLink, setMessengerLink] = useState("");
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [password, setPassword] = useState("");
  const [bio, setBio] = useState("");
  const [fullName, setFullName] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [securityQuestion, setSecurityQuestion] = useState(""); // Security question
  const [securityAnswer, setSecurityAnswer] = useState(""); // Security answer

  const canInvitePatients = userRole === "Primary Provider";

  const handleInvite = async (e) => {
    e.preventDefault();

    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    if (canInvitePatients) {
      const userId = localStorage.getItem("userId");

      try {
        const allDoctorsResponse = await fetch("https://api.tracktb.com/doctors", {
          headers: headers,
        });

        if (!allDoctorsResponse.ok) {
          throw new Error(`HTTP error! status: ${allDoctorsResponse.status}`);
        }

        const allDoctorsData = await allDoctorsResponse.json();
        const doctor = allDoctorsData.find((doc) => doc.userId.toString() === userId);

        if (doctor) {
          const payload = {
            patientId,
            phoneNumber,
            assignedProvider: doctor.doctorId.toString(),
          };

          const response = await fetch("https://api.tracktb.com/patients", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const responseData = await response.json();

          // Set the authentication code, link, and QR code value based on response data
          setAuthCode(responseData.authCode);
          setMessengerLink(responseData.messengerLink);
          setQrCodeValue(responseData.messengerLink); // Use messenger link as QR code value

          // Reset form fields
          setPatientId("");
          setPhoneNumber("");
        } else {
          console.error("Doctor with matching userId not found");
        }
      } catch (error) {
        console.error("Error fetching doctor data:", error);
      }
    } else {
      const payload = {
        emailAddress: email,
        password,
        specialization,
        licenseNumber,
        fullName,
        phoneNumber,
        bio,
        role: inviteRole,
        securityQuestion,
        securityAnswer,
      };
      console.log(JSON.stringify(payload));

      try {
        const response = await fetch("https://api.tracktb.com/doctors/register", {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const responseData = await response.json();
        console.log("New provider added successfully:", responseData);
  
        // Reset form fields
        setEmail("");
        setPassword("");
        setSpecialization("");
        setFullName("");
        setPhoneNumber("");
        setBio("");
        setInviteRole("");
        setLicenseNumber("");
        setSecurityQuestion("");
        setSecurityAnswer("");
      } catch (error) {
        console.error("Error adding new provider:", error);
      }
    }
  };

  return (
    <div id="background">
      <div id="page-container">
        <h1 className="page-title">Invite New Member</h1>
        <form onSubmit={handleInvite} className="form-container">
          {canInvitePatients ? (
            <>
              <input
                type="text"
                className="addNewProviderPageForm"
                placeholder="Patient ID"
                value={patientId}
                onChange={(e) => setPatientId(e.target.value)}
                required
              />
              <input
                type="tel"
                className="addNewProviderPageForm"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </>
          ) : (
            <>
              <input
                type="email"
                className="addNewProviderPageForm"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                className="addNewProviderPageForm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <input
                type="text"
                className="addNewProviderPageForm"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
              <input
                type="tel"
                className="addNewProviderPageForm"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              <input
                type="text"
                className="addNewProviderPageForm"
                placeholder="Specialization"
                value={specialization}
                onChange={(e) => setSpecialization(e.target.value)}
                required
              />
                <input
                type="text"
                className="addNewProviderPageForm"
                placeholder="License Number"
                value={licenseNumber}
                onChange={(e) => setLicenseNumber(e.target.value)}
                required
              />
              <textarea
                className="addNewProviderPageForm"
                placeholder="Bio"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                rows="3"
                required
              ></textarea>

              {/* Dropdown for security question */}
              <select
                value={securityQuestion}
                className="addNewProviderPageForm"
                onChange={(e) => setSecurityQuestion(e.target.value)}
                required
                style={{
                  padding: "10px",
                  marginBottom: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  fontFamily: '"Roboto", sans-serif',
                }}
              >
                <option value="">Select Security Question</option>
                <option value="What is my mother's maiden name?">
                  What is my mother's maiden name?
                </option>
                <option value="What is my favorite color?">
                  What is my favorite color?
                </option>
                <option value="What is my pet's name?">What is my pet's name?</option>
                <option value="What is my favorite movie?">
                  What is my favorite movie?
                </option>
                <option value="What is my favorite fruit?">
                  What is my favorite fruit?
                </option>
                <option value="What is my favorite song?">
                  What is my favorite song?
                </option>
              </select>

              {/* Input for security answer */}
              <input
                type="text"
                className="addNewProviderPageForm"
                placeholder="Security Answer"
                value={securityAnswer}
                onChange={(e) => setSecurityAnswer(e.target.value)}
                required
              />
              <select
                value={inviteRole}
                className="addNewProviderPageForm"
                onChange={(e) => setInviteRole(e.target.value)}
                required
                style={{
                  padding: "10px",
                  marginBottom: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  fontFamily: '"Roboto", sans-serif',
                }}
              >
                <option value="">Select Role</option>
                {rolePermissions[userRole]?.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </>
          )}
          <button type="submit" className="form-button">
            Invite
          </button>
        </form>

        {/* Display authentication code, messenger link, and QR code if available */}
        {authCode && (
          <div className="auth-details">
            <h3 className="auth-title">Patient Authentication Details</h3>
            <p><strong>Authentication Code:</strong> {authCode}</p>
            <p><strong>Messenger Link:</strong> <a href={messengerLink} target="_blank" rel="noopener noreferrer">{messengerLink}</a></p>
            <div className="qr-code-container">
              <QRCodeCanvas value={qrCodeValue} size={128} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddProviderPage;
