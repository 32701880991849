import React, { createContext, useContext, useEffect, useState } from 'react';

// Create context
const DataContext = createContext();

// Custom hook to use the DataContext
export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.tracktb.com/responses', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                localStorage.setItem('responses', JSON.stringify({ data, timestamp: new Date().getTime() }));
                setData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        const cachedData = localStorage.getItem('responses');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            setData(parsedData.data);
            setLoading(false);
        } else {
            fetchData();
        }
    }, []);

    return (
        <DataContext.Provider value={{ data, loading }}>
            {children}
        </DataContext.Provider>
    );
};
