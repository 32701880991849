import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import "../styles/homePage.css";

const COLORS = ['#ff6361', '#8884d8', '#ffc658', '#00C49F'];

const ADRSeverityPieChart = ({ setIsAdrSeverityPieChartLoading }) => {
  const [selectedADR, setSelectedADR] = useState('Weakness');
  const [chartData, setChartData] = useState([]);
  const [totalResponses, setTotalResponses] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const ADR_OPTIONS = [
    { label: 'Weakness', questionID: 4 },
    { label: 'Rash, itching, jaundice', questionID: 5 },
    { label: 'Abdominal pains, nausea, vomiting or diarrhea', questionID: 6 },
    { label: 'Chest pains or palpitations', questionID: 7 },
    { label: 'Joint pains', questionID: 8 },
    { label: 'Difficulty urinating', questionID: 9 },
    { label: 'Depression, anxiety, seizures or numbness', questionID: 10 },
    { label: 'Blurred vision', questionID: 11 },
    { label: 'Hearing difficulties', questionID: 12 },
    { label: 'Pallor', questionID: 13 },
  ];

  const Loading = () => {
    return (
      <div className="loading-overlay">
        <div className="spinner"></div>
      </div>
    );
  };

  const fetchData = async (adrLabel) => {
    const formattedADR = encodeURIComponent(adrLabel); // Encode ADR for URL
    const endpoint = `https://api.tracktb.com/responses/adr-severity?adr=${formattedADR}`;
    console.log(`Fetching data from endpoint: ${endpoint}`);

    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('JWT token not found in localStorage');
      }

      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Fetched data:', data);

      // Calculate total responses
      const total = data.reduce((sum, item) => sum + (item.rawCount || 0), 0); // Use `rawCount` if available

      setTotalResponses(total ? Math.round(total) : 0);
      setChartData(data.map((item) => ({
        name: item.name,
        value: item.value ? parseFloat(item.value.toFixed(1)) : 0,
      })));
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    const adrLabel = selectedADR;
    fetchData(adrLabel);
  }, [selectedADR]);

  console.log('Rendering PieChart with chartData:', chartData);

  return (
    <div className="adr-pie-container" style={{ position: 'relative' }}>
      <h2 className="adr-title">ADR Severity</h2>
      <select
        className="adr-select"
        value={selectedADR}
        onChange={(e) => {
          setSelectedADR(e.target.value);
        }}
      >
        {ADR_OPTIONS.map((option) => (
          <option key={option.label} value={option.label}>
            {option.label}
          </option>
        ))}
      </select>
      {loading ? (
        <Loading />
      ) : error ? (
        <p>Error: {error}</p>
      ) : chartData && chartData.length > 0 ? (
        <div className="pie-chart-container">
          <PieChart width={250} height={200}>
            <Pie
              data={chartData}
              cx={125}
              cy={100}
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `${value.toFixed(1)}%`} />
          </PieChart>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default ADRSeverityPieChart;