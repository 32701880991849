import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import { Box, Paper } from "@mui/material";
import "../styles/homePage.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(3),
  margin: "10px",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
}));

const Heading = styled("h3")({
  marginLeft: "10px",
});

const Stat = styled("h3")({
  marginRight: "10px",
  color: "rgb(113,146,224)",
});

const OverviewStats = () => {
  const [stats, setStats] = useState({
    totalLogs: null,
    totalPatients: null,
    fullAdherence: null,
    partialAdherence: null,
  });

  const fetchData = async () => {
    try {
      console.log("Fetching new data from API...");
      const response = await fetch('https://api.tracktb.com/responses/overview', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const fetchedStats = await response.json();
      console.log("Fetched stats from API:", fetchedStats);

      // Update state with API response
      setStats({
        totalLogs: fetchedStats.totalLogs,
        totalPatients: fetchedStats.totalPatients,
        fullAdherence: parseFloat(fetchedStats.fullAdherence).toFixed(2),
        partialAdherence: parseFloat(fetchedStats.partialAdherence).toFixed(2),
      });
    } catch (error) {
      console.error('Error fetching overview stats:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box className="overview-stats">
      <Item>
        <Heading>Total Logs</Heading>
        <Stat>{stats.totalLogs ?? 'N/A'}</Stat>
      </Item>
      <Item>
        <Heading>Total Patients</Heading>
        <Stat>{stats.totalPatients ?? 'N/A'}</Stat>
      </Item>
      <Item>
        <Heading>Full Adherence</Heading>
        <Stat>{stats.fullAdherence !== null ? `${stats.fullAdherence}%` : 'N/A'}</Stat>
      </Item>
      <Item>
        <Heading>Partial Adherence</Heading>
        <Stat>{stats.partialAdherence !== null ? `${stats.partialAdherence}%` : 'N/A'}</Stat>
      </Item>
    </Box>
  );
};

export default OverviewStats;