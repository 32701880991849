import React, { useState } from 'react';
import '../styles/TermsAndConditions.css';

function TermsAndConditions() {
  const [isAccepted, setIsAccepted] = useState(false);

  const acceptTerms = async () => {
    const userId = 6;
    const userRole = "Primary Provider";

    const requestBody = {
      userId,
      userRole,
    };

    try {
      const response = await fetch('https://api.tracktb.com/api/terms/accept', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(requestBody).toString(),
      });

      if (response.ok) {
        setIsAccepted(true);
      } else {
        console.error('Failed to accept terms:', response.statusText);
        alert('There was an issue accepting the terms. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="terms-container">
      {!isAccepted ? (
        <>
      <h1>Terms and Conditions</h1>
      <div className="terms-content">
        <p>These terms and conditions constitute the legal agreement between you (“user”, “you”, “your”) and TrackTB (“application developer/s”, “us”, “we”, “our”). This agreement sets forth the general terms and conditions of your use of the TrackTB application. If you do not accept these terms we will not grant you access to the service and the joining process will immediately terminate. By joining and using the service, you agree to be legally bound by this agreement.</p>
        <h2>Warranties and Representations</h2>
        <p>By using this application and by agreeing to these Terms, you warrant and represent that:</p>
        <ol>
          <li>You are of legal age and with legal capacity to enter into this agreement and perform the obligations hereunder
          </li>
          <li>All necessary approvals required for the due execution of the Agreement and the performance of your obligations under the same have been obtained and are in full force and effect. 
          </li>
          <li>You acknowledge that a physician-patient relationship is formed at the request of the physician/s who shall use the TrackTB’s features with you.
          </li>
          <li>You shall comply with all existing and future laws, decrees, orders, rules and regulations promulgated by the authorities and shall hold the Application Developer free and harmless from any obligations or liabilities that may arise from its violation of any of such laws, decrees, orders and rules and regulations.
          </li>
        </ol>
        <p>These representations and warranties shall survive the termination of of the agreement.</p>
        <h2>Use of the Application</h2>
        <p>You agree that you will use the services in accordance with this agreement. You also agree that, by creating an account in the application, you commit to be responsible for maintaining the security of your account. You shall be held fully responsible for all activities that occur under your account and any other actions taken in connection with it. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. In the event of any unauthorized use of your account or other breaches of security, you must immediately notify us.
        </p>
        <p>You agree that you will not use the services for any unlawful, immoral, or malicious purpose. You are likewise prohibited from using the application or its content: 
        </p>
        <ol>
          <li>To solicit others to perform or participate in any unlawful acts
          </li>
          <li>To violate any international, national federal, provincial, or state regulations, rules, laws, or local ordinances
          </li>
          <li>To infringe upon or violate our intellectual property property rights or the intellectual property rights of others
          </li>
          <li>To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability
          </li>
          <li>To submit false or misleading information, or to send, receive, publish, distribute, transmit, upload, or download any material which is offensive, abusive, defamatory, indecent, obscene, unlawful; or otherwise objectionable
          </li>
          <li>To introduce, upload or transmit viruses or any other type of malicious code, virus, or data that will or may be used in any way that will affect the functionality or operation of the services or of any other related application, other applications, any telecommunications equipment or any other computer software or hardware, or the Internet
          </li>
          <li>To collect or track the personal information, or otherwise invade the privacy of or cause annoyance or anxiety to any other person
          </li>
          <li>To spam, phish, pharm, pretect, spider, crawl, scrape, or otherwise transmit any unsolicited correspondence to any other person
          </li>
          <li>For any obscene or immoral purpose
          </li>
          <li>To damage, disable, overburden, impair, compromise, circumvent, or otherwise interfere with the security features of the application, the systems or security of the application developer or any related application, other applications, or the internet
          </li>
        </ol>
        <p>We reserve the right to suspend or terminate your use of the service or any related application for violating any of the prohibited useless. We may likewise suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our services. We may block your email address and Internet protocol address to prevent further registration.
        </p>
        <h2>User Content</h2>
        <p>We do not own any data, information or material that you submit in the application in the course of using the service. You shall have the sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted content. We may monitor content in the application submitted or created using our services by you. You expressly grant us permission to access, copy, distribute, store, transmit, reformat, display, and perform the content of your user account solely as required for the purpose of providing the services to you and other users of the application. Without limiting any of these representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable.
        </p>
        <h2>Intellectual property rights
        </h2>
        <p>This agreement does not transfer to you any intellectual property owned by application developers or third parties, other than the non-exclusive and nontransferable right to use it in accordance with this agreement. All rights, titles, and interests in and to such property remain (as between the parties) solely with the application developer. All trademarks, service marks, graphics, and logos used in connection with our application or services are trademarks of application developer or application developer licensors. Other trademarks, service marks, graphics, and logos used in connection with our application or services may be the trademarks of other third parties. Your use of our application and services may be the trademarks of other third parties. Your use of our application and services grants you no right or license to reproduce or otherwise use any application developer or third party trademarks.
        </p>
        <h2>Backups</h2>
        <p>We perform regular backups of the Content and will do our best to ensure completeness and accuracy of these backups. In the event of the hardware failure or data loss we will restore backups automatically to minimize the impact and downtime.
        </p>
        <h2>Links to other applications
        </h2>
        <p>Although this Application may be linked to others, we are not, directly or indirectly, implying any approval, association, sponsorship, or affiliation with any linked application, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of any businesses or individuals or the content of their applications. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You are advised to review the legal statements and other conditions of use of any application which you access through a link from this Application. Your linking to any other off-site applications is at your own risk.
        </p>
        <h2>Limitation of liability
        </h2>
        <p>The Application is provided to you on an “as is” and “as available” basis and therefore all warranties, conditions and other terms implied by statute or common law are, to the fullest extent permitted by law, excluded from this Agreement.
        </p>
        <p>To the fullest extent permitted by applicable law, in no event will the Application Developer, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use or content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if Application Developer has been advised as to the possibility of such damages or could have foreseen such damages. The Application Developer shall not be liable to you in respect of any losses caused by failures, errors, delays or interruptions relating to the Services, including any failure to supply the Services due to events which are beyond our reasonable control. The Application Developer shall not also be liable to you if any third party gains access to your connection to the service or your device or destroys or damages any data or information held by you or information about you which is held by us. The Application Developer shall have no liability in respect of indirect or consequential loss, such as loss of profits, business, costs, expenses, or any other form of economic loss.
        </p>
        <p>You understand that the application developer is only a liaison between you, the receiver of medical services, and your physician/s. Thus the application developer shall not be liable to you or the patient/s for the risks involved in using the service, including but not limited to 
        </p>
        <ol>
          <li>Delays in assessment and advice due to failure of the electronic system
          </li>
          <li>Adverse drug reactions, allergic reactions, or other judgment errors made by the licensed physician due to patient/s failure to provide accurate or complete medical information
          </li>
          <li>Temporary interruptions in the services
          </li>
        </ol>
        <h2>Termination
        </h2>
        <p>Notwithstanding any provision in this Agreement, the Application Provider may terminate this Agreement immediately without notice to you for any reason whatsoever. In which case, all rights granted to you under this Agreement shall cease and you must immediately cease all activities authorized by this Agreement, including your use of any Services. The Application Provider shall not be liable to you or any third person for any losses arising from termination of the Agreement under this section. However, termination of this Agreement for any cause shall be without prejudice to the rights or remedies of either party accrued prior to the date of termination.
        </p>
        <h2>Indemnification</h2>
        <p>You agree to indemnify and hold Application Developer and its affiliates harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Application or Services, or any willful misconduct on your part.
        </p>
        <h2>Relationship of the Parties</h2>
        <p>You agree to indemnify and hold Application Developer and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Application or Services, or any willful misconduct on your part.
        </p>
        <h2>Severability</h2>
        <p>All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
        </p>
        <h2>Dispute Resolution</h2>
        <p>The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of the Republic of the Philippines. The exclusive venue for actions related to the subject matter hereof shall be the appropriate courts of Manila, Philippines, to the exclusion of other courts.
        </p>
        <h2>Changes and amendments</h2>
        <p>We reserve the right to modify this Agreement or its policies relating to the Application or Services at any time, effective upon posting of an updated version of this Agreement in the Application. When we do, we will revise the updated date at the bottom of this page. Continued use of the Application after any such changes shall constitute your consent to such changes.
        </p>
        <h2>Acceptance of these terms</h2>
        <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Application or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Application and its Services.
        </p>
        <h2>Privacy</h2>
        <p>The terms of the Privacy Policy, available through a link on the log in page (Privacy Policy), are incorporated into this Agreement by reference and apply to the Service. Additionally, by using the Application, you acknowledge and agree that internet transmissions are never completely private or secure. You understand that any message or information you send using the Application may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.
        </p>
        <h2>Contact Information</h2>
        <p>If you have any questions about this agreement, you may send an e-mail to TrackTB through cinqtblehigh@gmail.com. 
        </p>
      </div>
      <button className="accept-button" onClick={acceptTerms}>Accept</button>
      </>
      ) : (
        <div className="accept-message">
          <h1>Thank You!</h1>
          <p>You have successfully accepted the terms and conditions.</p>
        </div>
      )}
    </div>
  );
}

export default TermsAndConditions;
