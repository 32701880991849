import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from "react-router-dom";
import SideBar from "./components/sideBar.js";
import NavBar from "./components/navBar.js";
import HomePage from "./pages/homePage.js";
import ProgAdminList from "./pages/progAdminList.js";
import AddProviderPage from "./pages/addNewProvider.js";
import PatientsPage from "./pages/patientList.js";
import PatientPage from "./pages/patientPage.js";
import ProviderList from "./pages/providerList.js";
import ForgotPassword from "./pages/forgotPassword.js";
import LoginPage from "./pages/loginPage.js";
import MyAccount from "./pages/myAccount.js";
import CaretakerLogin from "./pages/caretakerLoginPage.js";
import Survey from "./patient-survey/src/pages/Survey.js";
import TermsAndConditions from "./patient-survey/src/pages/TermsAndConditions.js";
import "./App.css";

function App() {

  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isCaretaker, setIsCaretaker] = React.useState(false);  // New state to handle caretaker visibility

  return (
    <Router>
      <div className="containerPage">
      {isAuthenticated && !isCaretaker && <SideBar />}
        <div className="content-wrapper">
          {isAuthenticated && <NavBar />}
          <div className="main-content">
            <Routes>
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/termsandconditions" element={<TermsAndConditions />} />
              <Route path="/survey/:oneTimeCode" element={<Survey />} />
              <Route path="/login" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/caretaker-login" element={<CaretakerLogin setIsAuthenticated={setIsAuthenticated} setIsCaretaker={setIsCaretaker} />} />
              {/* Redirect to login page if not authenticated */}
              <Route path="/" element={<Navigate replace to="/login" />} />
              <Route path="/caretaker-login" element={<CaretakerLogin />} />
              {/* Protect the home page and other routes */}
              {isAuthenticated ? (
                <>
                   <Route path="/dashboard" element={!isCaretaker ? <HomePage /> : <Navigate replace to="/login" />} />
                  <Route path="/invite-member" element={!isCaretaker ? <AddProviderPage /> : <Navigate replace to="/login" />} />
                  <Route path="/patients" element={!isCaretaker ? <PatientsPage /> : <Navigate replace to="/login" />} />
                  <Route path="/patient/:patientId" element={<PatientPage />} />
                  <Route path="/providers" element={!isCaretaker ? <ProviderList /> : <Navigate replace to="/login" />} />
                  <Route path="/my-account" element={!isCaretaker ? <MyAccount /> : <Navigate replace to="/login" />} />
                  <Route path="/prog-admin" element={!isCaretaker ? <ProgAdminList /> : <Navigate replace to="/login" />} />
                </>
              ) : (
                <Route path="*" element={<Navigate replace to="/login" />} />
              )}
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
