import React, { useEffect, useState } from 'react';
import "../styles/MyAccountPage.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const MyAccount = () => {
    const [userData, setUserData] = useState(null);
    const [editMode, setEditMode] = useState({ phoneNumber: false, bio: false });
    const [editedValues, setEditedValues] = useState({ phoneNumber: '', bio: '' });
    const [doctorDetails, setDoctorDetails] = useState(null);

    const saveUpdates = async () => {
        try {
            const response = await fetch(`https://api.tracktb.com/doctors/${doctorDetails.doctorId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phoneNumber: editedValues.phoneNumber,
                    bio: editedValues.bio
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const updatedDetails = await response.json();
            setDoctorDetails(updatedDetails);
            setEditedValues({ phoneNumber: updatedDetails.phoneNumber, bio: updatedDetails.bio });
            setEditMode({ phoneNumber: false, bio: false });
        } catch (error) {
            console.error("Failed to update doctor details:", error);
        }
    };

    useEffect(() => {
        const fetchDoctorDetails = async () => {
            const userId = localStorage.getItem('userId');

            try {
                const allDoctorsResponse = await fetch('https://api.tracktb.com/doctors', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!allDoctorsResponse.ok) {
                    throw new Error(`HTTP error! status: ${allDoctorsResponse.status}`);
                }

                const allDoctorsData = await allDoctorsResponse.json();
                const doctor = allDoctorsData.find(doc => doc.userId === parseInt(userId));

                if (doctor) {
                    const doctorDetailsResponse = await fetch(`https://api.tracktb.com/doctors/${doctor.doctorId}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!doctorDetailsResponse.ok) {
                        throw new Error(`HTTP error! status: ${doctorDetailsResponse.status}`);
                    }

                    const doctorDetailsData = await doctorDetailsResponse.json();
                    setDoctorDetails(doctorDetailsData);
                    setEditedValues({ phoneNumber: doctorDetailsData.phoneNumber, bio: doctorDetailsData.bio });
                } else {
                    console.error("Doctor with matching userId not found");
                }
            } catch (error) {
                console.error("Failed to fetch doctor details:", error);
            }
        };

        fetchDoctorDetails();
    }, []);

    return (
        <div className="account-container">
            {doctorDetails ? (
                <div className="profile-card-container">
                    <div className="profile-card">
                        <h1>{doctorDetails.fullName}</h1>
                        <h3 className="role">{doctorDetails.role} #{doctorDetails.doctorId}</h3>
                        <p>Specialization: {doctorDetails.specialization}</p>
                        <p>E-mail: {doctorDetails.emailAddress}</p>
                        <p>
                            Phone Number:
                            {editMode.phoneNumber ? (
                                <input
                                    type="text"
                                    value={editedValues.phoneNumber}
                                    onChange={e => setEditedValues(prev => ({ ...prev, phoneNumber: e.target.value }))}
                                    onBlur={() => {
                                        setEditMode(prev => ({ ...prev, phoneNumber: false }));
                                        saveUpdates();
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <>
                                    {doctorDetails.phoneNumber}
                                    <button onClick={() => setEditMode(prev => ({ ...prev, phoneNumber: true }))} className="edit-icon-btn">
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>
                                </>
                            )}
                        </p>
                    </div>
                    <div className="profile-card">
                        <h1>About Me</h1>
                        <div className="bio-box">
                            {editMode.bio ? (
                                <textarea
                                    value={editedValues.bio}
                                    onChange={e => setEditedValues(prev => ({ ...prev, bio: e.target.value }))}
                                    onBlur={() => {
                                        setEditMode(prev => ({ ...prev, bio: false }));
                                        saveUpdates();
                                    }}
                                    autoFocus
                                    rows="10" // Increase the number of rows for a longer about me section
                                    className="bio-textarea"
                                />
                            ) : (
                                <div className="bio-container">
                                    <span className="bio-text">{doctorDetails.bio}</span>
                                    <button onClick={() => setEditMode(prev => ({ ...prev, bio: true }))} className="edit-icon-btn">
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading doctor details...</p>
            )}
        </div>
    );
};

export default MyAccount;
