import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginPage.css';
import logo from '../styles/images/SideBarImage.png';

function CareTakerLogin({ setIsAuthenticated, setIsCaretaker }) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpCode, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const handleSendOTP = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://tracktb.com/otp/request-otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber }),
            });

            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setOtpSent(true);
                return;
            }

            const data = await response.json();
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setOtpSent(true);
        } catch (error) {
            console.error('Sending OTP error:', error);
            setLoginError('Failed to send OTP. Please try again.');
        }
    };

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.tracktb.com/otp/verify-otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber, otpCode }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            localStorage.setItem('jwtToken', data.jwt);
            localStorage.setItem('userRole', data.userRole);
            localStorage.setItem('userId', data.userId);
            console.log(`jwt: ${data.jwt}`);
            console.log(`userRole: ${data.userRole}`);
            console.log(`userId: ${data.userId}`);

            // Fetch all patients and filter the one with the matching phone number
            const patientsResponse = await fetch('https://api.tracktb.com/patients', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!patientsResponse.ok) {
                throw new Error('Failed to fetch patients');
            }

            const patientsData = await patientsResponse.json();
            const matchedPatient = patientsData.find(patient => patient.phoneNumber === phoneNumber);

            if (!matchedPatient) {
                throw new Error('Patient not found');
            }

            const patientId = matchedPatient.patientId;
            console.log(`patientId: ${patientId}`);

            setIsAuthenticated(true);
            setIsCaretaker(true);  // Set the caretaker state to true
            navigate(`/patient/${patientId}`);
        } catch (error) {
            console.error('OTP verification error:', error);
            setLoginError('Invalid OTP. Please try again.');
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={otpSent ? handleVerifyOTP : handleSendOTP}>
                <h1>Caretaker Sign In</h1>
                <p>{otpSent ? "Enter Your OTP" : "Please enter patient's phone number to receive One-Time Password (OTP)"}</p>
                {otpSent ? (
                    <input
                        type="text"
                        placeholder="OTP"
                        value={otpCode}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                    />
                ) : (
                    <input
                        type="text"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                    />
                )}
                {loginError && <div className="error-message">{loginError}</div>}
                <button type="submit">{otpSent ? "Verify OTP" : "Send OTP"}</button>
            </form>
        </div>
    );
}

export default CareTakerLogin;
