import React, { useState, useEffect } from 'react';
import ExportData from './exportData.js';

const ADR_OPTIONS = [
    { label: 'Weakness', questionID: 4 },
    { label: 'Rash, itching, jaundice', questionID: 5 },
    { label: 'Abdominal pains, nausea, vomiting or diarrhea', questionID: 6 },
    { label: 'Chest pains or palpitations', questionID: 7 },
    { label: 'Joint pains', questionID: 8 },
    { label: 'Difficulty urinating', questionID: 9 },
    { label: 'Depression, anxiety, seizures or numbness', questionID: 10 },
    { label: 'Blurred vision', questionID: 11 },
    { label: 'Hearing difficulties', questionID: 12 },
    { label: 'Pallor', questionID: 13 }
];

const fetchResponsesByPatientId = async (patientId) => {
    try {
        const response = await fetch(`https://api.tracktb.com/responses/${patientId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data || [];
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

const processLogs = (responses) => {
    if (!Array.isArray(responses)) {
        console.error('Expected responses to be an array, but got:', responses);
        return [];
    }

    const logs = [];

    // Group responses by date
    const groupedResponses = responses.reduce((acc, response) => {
        const date = new Date(response.timestamp).toLocaleDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(response);
        return acc;
    }, {});

    // Determine the start date from the responses and set the end date to today
    const startDate = new Date(Math.min(...responses.map(r => new Date(r.timestamp))));
    const endDate = new Date(); // Today's date

    // Iterate over all dates in the range from startDate to today's date
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const currentDate = d.toLocaleDateString();
        const dayResponses = groupedResponses[currentDate];

        if (dayResponses) {
            // Process the responses for this date
            const adherence = dayResponses.find(r => r.medicationtaken)?.medicationtaken || 'No Response';

            // Collect unique ADRs for the day
            const adrs = dayResponses
                .flatMap(r => Object.entries(r.adrs || {}))
                .map(([key, value]) => `${value} ${key}`) // Format severity + ADR name
                .filter((adr, index, self) => self.indexOf(adr) === index); // Deduplicate ADRs

            logs.push({
                date: currentDate,
                adherence,
                adrs: adrs.length > 0 ? adrs.join(', ') : 'No ADRs reported'
            });
        } else {
            // No responses for this date, add a "No Response" log
            logs.push({
                date: currentDate,
                adherence: 'No Response',
                adrs: 'No response for this day'
            });
        }
    }

    return logs;
};

const PatientLogs = ({ patientId }) => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const responses = await fetchResponsesByPatientId(patientId);
                const processedLogs = processLogs(responses);
                setLogs(processedLogs);
            } catch (error) {
                console.error('Error fetching patient logs:', error);
            }
        };

        loadData();
    }, [patientId]);

    return (
        <div className="patient-logs-container">
            <h2>Patient Logs</h2>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Adherence</th>
                        <th>ADRs</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'even-row' : ''}>
                            <td>{log.date}</td>
                            <td>{log.adherence}</td>
                            <td>{log.adrs}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="button-container">
                {/* Pass the `patientId` directly */}
                <ExportData patientId={patientId} />
            </div>
        </div>
    );
};

export default PatientLogs;