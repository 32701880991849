import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import PatientOverview from '../components/patientOverview';
import PatientLogs from '../components/patientLogs';
import '../styles/patientsPage.css';


const PatientPage = () => {
    const { patientId } = useParams();

    return (
        <div className='patient-page-container'>
            <div className='patient-page-components'>
                <PatientOverview patientId={patientId} />
            </div>
            <div className='patient-page-components'>
                <PatientLogs patientId={patientId} />
            </div>
        </div>
    );
};

export default PatientPage;