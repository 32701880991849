import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import SidebarImage from '../styles/images/SideBarImage.png';

const fetchPatientDataByPatientId = async (patientId) => {
    try {
        const response = await fetch(`https://api.tracktb.com/responses/${patientId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data || [];
    } catch (error) {
        console.error(`Error fetching data for patient ${patientId}:`, error);
        return [];
    }
};

const processLogs = (responses) => {
    const logs = [];
    const groupedResponses = responses.reduce((acc, response) => {
        const date = new Date(response.timestamp).toLocaleDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(response);
        return acc;
    }, {});

    const startDate = responses.length ? new Date(Math.min(...responses.map(r => new Date(r.timestamp)))) : new Date();
    const endDate = new Date();

    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const currentDate = d.toLocaleDateString();
        const dayResponses = groupedResponses[currentDate];

        if (dayResponses) {
            const medicationTaken = dayResponses.find(r => r.medicationtaken)?.medicationtaken || 'No Response';
            const adrs = dayResponses
                .flatMap(r => Object.entries(r.adrs || {}))
                .map(([key, value]) => `${value} ${key}`)
                .filter((adr, index, self) => self.indexOf(adr) === index);

            logs.push({
                date: currentDate,
                adherence: medicationTaken,
                adrs: adrs.length > 0 ? adrs.join(', ') : 'No ADRs reported',
            });
        } else {
            logs.push({
                date: currentDate,
                adherence: 'No logs for this day',
                adrs: 'No logs for this day',
            });
        }
    }

    return logs;
};

const ExportData = ({ patientId }) => {
    const [patientOverview, setPatientOverview] = useState({
        fullDoses: 0,
        partialDoses: 0,
        missedDoses: 0,
        adherenceRate: 0,
        severeAdrs: [],
    });
    const [patientLogs, setPatientLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const responses = await fetchPatientDataByPatientId(patientId);

            if (!Array.isArray(responses)) {
                console.error('Expected responses to be an array, but got:', responses);
                return;
            }

            const fullDoses = responses.filter((r) => r.medicationtaken === 'All').length;
            const partialDoses = responses.filter((r) => r.medicationtaken === 'Some').length;
            const missedDoses = responses.filter((r) => r.medicationtaken === 'None').length;
            const totalDoses = fullDoses + partialDoses + missedDoses;
            const adherenceRate = totalDoses > 0 ? ((fullDoses / totalDoses) * 100).toFixed(2) : 0;

            const severeAdrs = responses
                .flatMap((r) =>
                    Object.entries(r.adrs || {}).filter(([_, severity]) => severity === 'Severe').map(([adr]) => adr)
                );

            setPatientOverview({
                fullDoses,
                partialDoses,
                missedDoses,
                adherenceRate,
                severeAdrs: Array.from(new Set(severeAdrs)),
            });

            setPatientLogs(processLogs(responses));
            setLoading(false);
        };

        fetchData();
    }, [patientId]);

    const exportPDF = () => {
        const doc = new jsPDF();
        const img = new Image();
        img.src = SidebarImage;

        doc.addImage(img, 'PNG', 10, 10, 50, 45);
        const startY = 60;

        doc.text(`Patient Overview for ID: ${patientId}`, 70, 30);

        doc.autoTable({
            startY,
            head: [['Full Doses', 'Partial Doses', 'Missed Doses', 'Adherence Rate', 'Severe ADRs']],
            body: [[
                patientOverview.fullDoses,
                patientOverview.partialDoses,
                patientOverview.missedDoses,
                `${patientOverview.adherenceRate}%`,
                patientOverview.severeAdrs.join(', ') || 'No severe ADRs',
            ]],
        });

        doc.text(`Patient Logs`, 70, doc.autoTable.previous.finalY + 20);
        doc.autoTable({
            startY: doc.autoTable.previous.finalY + 30,
            head: [['Date', 'Adherence', 'ADRs']],
            body: patientLogs.map(log => [log.date, log.adherence, log.adrs]),
        });

        doc.save(`patient_${patientId}.pdf`);
    };

    const exportCSV = () => {
        const csvData = [
            {
                FullDoses: patientOverview.fullDoses,
                PartialDoses: patientOverview.partialDoses,
                MissedDoses: patientOverview.missedDoses,
                AdherenceRate: `${patientOverview.adherenceRate}%`,
                SevereADRs: patientOverview.severeAdrs.join(', '),
            },
            ...patientLogs.map(log => ({
                Date: log.date,
                Adherence: log.adherence,
                ADRs: log.adrs,
            })),
        ];

        const worksheet = XLSX.utils.json_to_sheet(csvData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Patient Data');
        const csvOutput = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
        saveAs(new Blob([csvOutput], { type: 'text/csv' }), `patient_${patientId}.csv`);
    };

    const exportXLSX = () => {
        const xlsxData = [
            {
                FullDoses: patientOverview.fullDoses,
                PartialDoses: patientOverview.partialDoses,
                MissedDoses: patientOverview.missedDoses,
                AdherenceRate: `${patientOverview.adherenceRate}%`,
                SevereADRs: patientOverview.severeAdrs.join(', '),
            },
            ...patientLogs.map(log => ({
                Date: log.date,
                Adherence: log.adherence,
                ADRs: log.adrs,
            })),
        ];

        const worksheet = XLSX.utils.json_to_sheet(xlsxData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Patient Data');
        const xlsxOutput = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([xlsxOutput], { type: 'application/octet-stream' }), `patient_${patientId}.xlsx`);
    };

    if (loading) {
        return <p>Loading data, please wait...</p>;
    }

    return (
        <div className="export-data-container">
            <h2 className="export-title">Export Patient Data</h2>
            <div className="button-container">
                <button className="export-button" onClick={exportPDF}>Export as PDF</button>
                <button className="export-button" onClick={exportCSV}>Export as CSV</button>
                <button className="export-button" onClick={exportXLSX}>Export as XLSX</button>
            </div>
        </div>
    );
};

export default ExportData;