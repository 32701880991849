import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../styles/providersList.css';

const ProgAdminList = () => {
    const [admins, setAdmins] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [selectedAdminId, setSelectedAdminId] = useState(null);
    const [selectedAdminDetails, setSelectedAdminDetails] = useState(null);
    const [isAdminSelected, setIsAdminSelected] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAdminsData = async () => {
            try {
                const response = await fetch('https://api.tracktb.com/api/users/admins', {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                let adminsData = await response.json();
                setAdmins(adminsData);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        fetchAdminsData();
    }, []);

    const handleFilterChange = (e) => {
        setSearchFilter(e.target.value.toLowerCase());
    };

    const handleSearch = () => {
        const filteredAdmins = admins.filter(admin =>
            admin.adminId.toString().includes(searchFilter) ||
            (admin.emailAddress && admin.emailAddress.toLowerCase().includes(searchFilter))
        );
        setAdmins(filteredAdmins);
    };





    const handleInviteClick = () => {
        navigate('/invite-member');
    };

    return (
        <div className="providers-page-container">
            <div className={`providers-list-container ${isAdminSelected ? 'shrink' : ''}`}>
                <div className="providers-list-header">
                    <h2>Program Admins List</h2>
                    <button className="invite-button" onClick={handleInviteClick}>Invite New Admin</button>
                </div>
                <div>
                    <input
                        className="search-provider"
                        placeholder="Enter identification number or email"
                        value={searchFilter}
                        onChange={handleFilterChange}
                    />
                    <button className="search-button" onClick={handleSearch}>Search</button>
                </div>
                <table className="providers-list-table">
                    <thead>
                        <tr>
                            <th>Identification #</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Phone Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {admins.map((admin, index) => (
                            <tr>
                                <td>{admin.userId}</td>
                                <td>{admin.emailaddress}</td>
                                <td>{admin.role}</td>
                                <td>{admin.phonenumber}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProgAdminList;
