import React, { useState } from 'react';
import '../styles/Survey.css';

function ADRQuestion({ symptom, onSeverityChange }) {
  const [isChecked, setIsChecked] = useState(false);
  const [severity, setSeverity] = useState('');

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setSeverity(''); // Reset severity if unchecked
    if (!isChecked) {
      onSeverityChange(symptom, ''); // Remove symptom severity from survey data if unchecked
    }
  };

  const handleSeverityChange = (selectedSeverity) => {
    setSeverity(selectedSeverity);
    onSeverityChange(symptom, selectedSeverity); // Pass data to parent component
  };

  return (
    <div className="adr-question">
      <label className="adr-label">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        {symptom}
      </label>

      {/* Show severity options if symptom is selected */}
      {isChecked && (
        <div className="severity-options">
          {['Severe', 'Moderate', 'Mild'].map((level) => (
            <button
              type="button"
              key={level}
              className={`severity-button ${severity === level ? 'active' : ''}`}
              onClick={() => handleSeverityChange(level)}
            >
              {level}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default ADRQuestion;

