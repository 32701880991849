import React, { useState, useEffect } from 'react';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';

const DateRangeFilter = ({ dateRange, setDateRange }) => {
  const [rangeStyle, setRangeStyle] = useState({});

  useEffect(() => {
    const startOfYear = new Date(dateRange[0].getFullYear(), 0, 1);
    const today = new Date();
    const totalDays = (today - startOfYear) / (1000 * 3600 * 24);

    const startDay = (dateRange[0] - startOfYear) / (1000 * 3600 * 24);
    const endDay = (dateRange[1] - startOfYear) / (1000 * 3600 * 24);
    const startPercentage = (startDay / totalDays) * 100;
    const endPercentage = (endDay / totalDays) * 100;

    setRangeStyle({
      background: `linear-gradient(to right, #ddd ${startPercentage-1}%, #1472D9 ${startPercentage-1}%, #1472D9 ${endPercentage+1}%, #ddd ${endPercentage}%)`,
      height: '10px',
      borderRadius: '5px',
      margin: '10px 0'
    });

  }, [dateRange]);

  return (
    <div className="date-range-filter">
      <p>Select a date range:</p>
      <DateRangePicker showOneCalendar value={dateRange} onChange={setDateRange} />
      <div style={rangeStyle}></div>
    </div>
  );
};

export default DateRangeFilter;
