import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SurveyQuestion from '../components/SurveyQuestion';
import ADRQuestion from '../components/ADRQuestion';
import '../styles/Survey.css';

function Survey() {
  const [surveyData, setSurveyData] = useState({
    responses: {
      medicationtaken: '',
      reason: '',
      adrs: '',
      adrsResponses: {},  // Object to store ADR symptoms and their severity
    },
  });

  const [currentDate, setCurrentDate] = useState('');
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [showReason, setShowReason] = useState(false);

  useEffect(() => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString();
    setCurrentDate(formattedDate);
  }, []);

  // Handle change for non-ADR questions
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSurveyData((prevData) => ({
      ...prevData,
      responses: {
        ...prevData.responses,
        [name]: value,
      },
    }));

    if (name === 'medicationtaken') {
      setShowReason(value === 'Some' || value === 'None');
    }

    if (name === 'adrs') {
      setShowFollowUp(value === 'Yes');
    }
  };

  // Handle change for ADR questions
  const handleADRChange = (symptom, severity) => {
    setSurveyData((prevData) => ({
      ...prevData,
      responses: {
        ...prevData.responses,
        adrsResponses: {
          ...prevData.responses.adrsResponses,
          [symptom]: severity,
        },
      },
    }));
  };

  const extractOneTimeCode = () => {
    const path = window.location.pathname; // Get the path from the URL
    const segments = path.split('/'); // Split the path into segments
    return segments[segments.length - 1]; // The last segment is the code
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const oneTimeCode = extractOneTimeCode();
    console.log(oneTimeCode);

    let requestData = {};

    if (surveyData.responses.medicationtaken === 'Some' || surveyData.responses.medicationtaken === 'None') {
      requestData = {
        medicationtaken: surveyData.responses.medicationtaken,
        reason: surveyData.responses.reason,
        adrs: surveyData.responses.adrsResponses,
      };
    } else {
      requestData = {
        medicationtaken: surveyData.responses.medicationtaken,
        adrs: surveyData.responses.adrsResponses,
      };
    }

    console.log(JSON.stringify(requestData, null, 2)); 
  
    try {
      // Send the request using fetch
      const response = await fetch(`https://api.tracktb.com/responses/${oneTimeCode}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData), // Remove the wrapping object
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      alert('You\'re doing great! Keep it up and stay committed to your health.');
    } catch (error) {
      console.error('Error submitting survey:', error);
    }
  };

  return (
    <div className="survey-container">
      <h1>Daily Survey<br/>
      <span className="survey-date">{currentDate}</span> {/* Display today's date */}
      </h1>
      <form onSubmit={handleSubmit} className="survey-form">
        <SurveyQuestion
          question="How many TB pills did you take today?"
          name="medicationtaken"
          options={["All", "Some", "None"]}
          selectedOption={surveyData.responses.medicationtaken}
          onChange={handleChange}
        />
        <SurveyQuestion
          question="What prevented you from taking the full-dose medication?"
          name="reason"
          options={[
            "I forgot",
            "I didn't have the medicines with me",
            "The medicines make me feel sick",
            "I ran out of medicines",
          ]}
          selectedOption={surveyData.responses.reason}
          onChange={handleChange}
        />
        <SurveyQuestion
          question="Have you experienced any adverse drug reaction (ADR)?"
          name="adrs"
          options={["Yes", "No"]}
          selectedOption={surveyData.responses.adrs}
          onChange={handleChange}
        />

        {/* Follow-up ADR Questions */}
        {showFollowUp && (
          <div className="adr-section">
            <p>Please indicate the severity of the ADRs experienced:</p>
            <ADRQuestion symptom="Weakness" onSeverityChange={handleADRChange} />
            <ADRQuestion symptom="Rash, itching, jaundice" onSeverityChange={handleADRChange} />
            <ADRQuestion symptom="Abdominal pains, nausea, vomiting or diarrhea" onSeverityChange={handleADRChange} />
            <ADRQuestion symptom="Chest pains or palpitations" onSeverityChange={handleADRChange} />
            <ADRQuestion symptom="Joint pains" onSeverityChange={handleADRChange} />
            <ADRQuestion symptom="Difficulty urinating" onSeverityChange={handleADRChange} />
            <ADRQuestion symptom="Depression, anxiety, seizures or numbness" onSeverityChange={handleADRChange} />
            <ADRQuestion symptom="Blurred vision" onSeverityChange={handleADRChange} />
            <ADRQuestion symptom="Hearing difficulties" onSeverityChange={handleADRChange} />
            <ADRQuestion symptom="Pallor" onSeverityChange={handleADRChange} />
            {/* Add more ADR symptoms here as needed */}
          </div>
        )}
        
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}

export default Survey;
