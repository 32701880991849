import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../styles/patientsPage.css';

const PatientsPage = () => {
    const navigate = useNavigate();
    const [allPatients, setAllPatients] = useState([]);
    const [displayedPatients, setDisplayedPatients] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const doctorId = localStorage.getItem('doctorId');
    const userRole = localStorage.getItem('userRole');
    const isPrimaryProvider = userRole === 'Primary Provider';
       
    const fetchPatients = async () => {
            try {
                const url = userRole === 'Primary Provider'
                    ? `https://api.tracktb.com/patients/provider/${doctorId}`
                    : `https://api.tracktb.com/patients`;
        
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
        
                if (!response.ok) {
                    throw new Error(`Failed to fetch patients: ${response.statusText}`);
                }
        
                const data = await response.json();
                return data;
            } catch (err) {
                console.error('Error fetching patients:', err);
                setError(err.message);
                return [];
            }
    };


    const fetchAdherenceRate = async (patientId) => {
        try {
            const response = await fetch(`https://api.tracktb.com/responses/${patientId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (response.status === 404) {
                console.warn(`No responses found for patient ${patientId}`);
                return 0;
            }

            if (!response.ok) {
                throw new Error(`Failed to fetch responses for patient ${patientId}`);
            }

            const data = await response.json();
            const fullDoses = data.filter((r) => r.medicationtaken === 'All').length;
            const partialDoses = data.filter((r) => r.medicationtaken === 'Some').length;
            const missedDoses = data.filter((r) => r.medicationtaken === 'None').length;
            const totalDoses = fullDoses + partialDoses + missedDoses;

            return totalDoses > 0 ? ((fullDoses / totalDoses) * 100).toFixed(2) : 0;
        } catch (error) {
            console.error(`Error calculating adherence rate for patient ${patientId}:`, error);
            return 0;
        }
    };

    useEffect(() => {
        const loadPatients = async () => {
            setLoading(true);
            try {
                const patients = await fetchPatients();
                console.log('Patients fetched in useEffect:', patients);
    
                if (!Array.isArray(patients)) {
                    throw new Error('Invalid patients data: Expected an array');
                }
    
                const updatedPatients = await Promise.all(
                    patients.map(async (patient) => {
                        const adherenceRate = await fetchAdherenceRate(patient.patientId);
                        return { ...patient, adherenceRate };
                    })
                );
    
                console.log('Updated Patients with adherence rates:', updatedPatients);
    
                setAllPatients(updatedPatients);
                setDisplayedPatients(updatedPatients);
            } catch (err) {
                console.error('Error in loadPatients:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
    
        loadPatients();
    }, []);

    const handleFilterChange = (e) => {
        setSearchFilter(e.target.value);
    };

    const handleSearch = () => {
        const filteredPatients = allPatients.filter((patient) =>
            (patient.patientId ?? '').toString().includes(searchFilter)
        );
        setDisplayedPatients(filteredPatients);
    };

    const handleInviteClick = () => {
        navigate('/invite-member');
    };

    const handlePatientClick = (patientId) => {
        navigate(`/patient/${patientId}`);
    };

    if (loading) return <p>Loading patients...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="patient-list-container">
            <div className="patient-list-header">
                <h2>Patient List</h2>
                {isPrimaryProvider && (
                    <button className="invite-button" onClick={handleInviteClick}>
                        Invite New Patient
                    </button>
                )}
            </div>
            <div>
                <input
                    className="search-patient"
                    placeholder="Enter patient ID"
                    value={searchFilter}
                    onChange={handleFilterChange}
                />
                <button className="search-button" onClick={handleSearch}>
                    Search
                </button>
            </div>

            <table className="patient-list-table">
                <thead>
                    <tr>
                        <th>TB ID</th>
                        <th>Phone Number</th>
                        <th>Adherence Rate</th>
                        {userRole !== 'Primary Provider' && <th>Assigned Provider</th>}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {displayedPatients.map((patient) => (
                        <tr key={patient.patientId}>
                            <td>{isPrimaryProvider ? patient.patientId : '*******'}</td>
                            <td>{isPrimaryProvider ? patient.phoneNumber : '***-***-****'}</td>
                            <td>
                                <div className="progress-bar-background" title={`${Math.floor(patient.adherenceRate)}%`}>
                                    <div
                                        className="progress-bar-foreground"
                                        style={{ width: `${patient.adherenceRate}%` }}
                                    ></div>
                                </div>
                            </td>
                            {userRole !== 'Primary Provider' && <td>{patient.assignedProvider}</td>}
                            <td>
                                <NavLink
                                    style={{ textDecoration: 'none' }}
                                    to={`/patient/${patient.patientId}`}
                                    className={({ isActive }) => isActive ? 'active' : undefined}
                                >
                                    <ArrowForwardIcon className="arrowIcon" />
                                </NavLink>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PatientsPage;


