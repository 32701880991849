import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Helper to format date arrays into "YY/MM/DD" strings
const formatDate = (dateArray) => {
  const [year, month, day] = dateArray;
  return `${String(year).slice(-2)}/${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}`;
};

// Generate a complete range of dates
const generateDateRange = (start, end) => {
  const range = [];
  let currentDate = new Date(start);
  const endDate = new Date(end);

  while (currentDate <= endDate) {
    range.push(
      `${String(currentDate.getFullYear()).slice(-2)}/${String(currentDate.getMonth() + 1).padStart(
        2,
        '0'
      )}/${String(currentDate.getDate()).padStart(2, '0')}`
    );
    currentDate.setDate(currentDate.getDate() + 1); // Increment by 1 day
  }
  return range;
};

const AdherenceChart = ({ dateRange }) => {
  const [adherenceData, setAdherenceData] = useState([]);

  const fetchData = async () => {
    const startDate = dateRange[0].toISOString().split('T')[0]; // Format as YYYY-MM-DD
    const endDate = dateRange[1].toISOString().split('T')[0]; // Format as YYYY-MM-DD

    try {
      const response = await fetch(
        `https://api.tracktb.com/responses/date?start=${startDate}&end=${endDate}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responses = await response.json();

      // Map response data by formatted dates
      const responseMap = new Map(
        responses.map((entry) => [
          formatDate(entry.date),
          {
            full: entry.full || 0,
            some: entry.some || 0,
            none: entry.none || 0,
            noResponse: entry.noResponse || 0,
          },
        ])
      );

      // Generate the full date range and fill missing dates
      const fullDateRange = generateDateRange(dateRange[0], dateRange[1]).map((date) => ({
        date,
        full: responseMap.get(date)?.full || 0,
        some: responseMap.get(date)?.some || 0,
        none: responseMap.get(date)?.none || 0,
        noResponse: responseMap.get(date)?.noResponse || 0,
      }));

      setAdherenceData(fullDateRange);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  return (
    <div className="adherence-chart-container">
      <style>
        {`
          .custom-tooltip {
            background: rgba(255, 255, 255, 0.8);
            border: 1px solid #ccc;
            padding: 10px;
            font-size: 12px;
          }

          .custom-tooltip p {
            margin: 0;
            padding: 2px 0;
          }
        `}
      </style>
          <ResponsiveContainer width="100%" height={250} className="adherence-chart">
      <BarChart
        data={adherenceData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <XAxis
          dataKey="date"
          tick={{ fontSize: 10, angle: -45, dy: 10 }} // Angled labels to prevent overlap
          interval="preserveStartEnd" // Dynamically adjust ticks
        />
        <YAxis
          domain={[0, 100]}
          ticks={[25, 50, 75, 100]}
          tickFormatter={(value) => `${value}%`}
        />
        <Tooltip
          formatter={(value, name) => {
            if (typeof value === 'number' && (name === 'full' || name === 'some' || name === 'none')) {
              return [`${value.toFixed(2)}%`, name];
            }
            return [value, name];
          }}
          content={({ payload, label }) => {
            if (payload && payload.length) {
              const data = payload[0].payload;
              return (
                <div className="custom-tooltip">
                  <p className="label">{`Date: ${label}`}</p>
                  <p>{`Full: ${data.full.toFixed(2)}%`}</p>
                  <p>{`Some: ${data.some.toFixed(2)}%`}</p>
                  <p>{`None: ${data.none.toFixed(2)}%`}</p>
                  <p>{`No Response: ${data.noResponse} patients`}</p>
                </div>
              );
            }
            return null;
          }}
        />
        <Legend wrapperStyle={{ top: -35, left: 0 }} />
        <Bar dataKey="full" stackId="a" fill="#82ca9d" />
        <Bar dataKey="some" stackId="a" fill="#ffc658" />
        <Bar dataKey="none" stackId="a" fill="#ff6361" />
      </BarChart>
    </ResponsiveContainer>
    </div>
  );
};

export default AdherenceChart;