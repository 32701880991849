import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as CogIcon } from '../styles/icons/settings-svgrepo-com.svg';
import { ReactComponent as UserIcon } from '../styles/icons/user-circle-svgrepo-com.svg';
import '../styles/navBar.css';
import ClearCacheButton from '../components/clearCacheButton';


const NavBar = () => {
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole') || 'patient'); // Default to 'patient' if not specified

  useEffect(() => {
    const userId = localStorage.getItem('userId'); // Retrieve the userId from local storage

    const fetchDoctorIdByUserId = async () => {
      try {
        const response = await fetch('https://api.tracktb.com/doctors', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const doctors = await response.json();
        const matchingDoctor = doctors.find(doctor => doctor.userId.toString() === userId);

        if (matchingDoctor) {
          fetchDoctorDetails(matchingDoctor.doctorId); // Fetch the details for the matching doctor
        } else {
          console.error('No matching doctor found');
        }
      } catch (error) {
        console.error("Failed to fetch doctors:", error);
      }
    };

    const fetchDoctorDetails = async (doctorId) => {
      try {
        const response = await fetch(`https://api.tracktb.com/doctors/${doctorId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const doctorDetails = await response.json();
        setUserName(doctorDetails.fullName); // Set the doctor's full name as the user name
      } catch (error) {
        console.error("Failed to fetch doctor details:", error);
      }
    };

    fetchDoctorIdByUserId();
  }, []);

  const getPartOfDay = () => {
    const hour = new Date().getHours();
    if (hour < 12) return '☀️ Good morning';
    if (hour < 18) return '🌇 Good afternoon';
    return '🌙 Good evening';
  };
  
  return (
    <div className="nav-bar">
      {userRole === 'Patient' ? (
        <>
          <span className="user-greeting">{`${getPartOfDay()}, ${userName || 'Guest!'}`}</span>
        </>
      ) : (
        <>
        <span className="user-greeting">
          {userRole === 'Super Admin'
            ? `${getPartOfDay()}, Super Administrator!`
            : userRole === 'Program Admin'
            ? `${getPartOfDay()}, Program Administrator!`
            : `${getPartOfDay()}, ${userName}`}
        </span>
          <div className="nav-icons"> 
        {/*}  <ClearCacheButton />  */}
          {userRole !== 'Super Admin' && userRole !== 'Program Admin' && (
            <NavLink to="/my-account" className={({ isActive }) => (isActive ? 'navBaricon-button active' : 'navBaricon-button')}>
              <UserIcon />
            </NavLink>
          )}

          <NavLink to="/settings" className={({ isActive }) => isActive ? 'navBaricon-button active' : 'navBaricon-button'}>
            <CogIcon />
          </NavLink>  

          <ClearCacheButton />
         
          </div>
        </>
      )}
    </div>
  );
};
  
  export default NavBar;