import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Survey.css'; // Use the same CSS file for styling

function SurveyQuestion({ question, name, options, selectedOption, onChange }) {
  return (
    <div className="question">
      <p>{question}</p>
      {options.map((option) => (
        <label key={option}>
          <input
            type="radio"
            name={name}
            value={option}
            onChange={(e) => onChange(e)}
            checked={selectedOption === option}
          />
          {option}
        </label>
      ))}
    </div>
  );
}

SurveyQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SurveyQuestion;