import React from 'react';
import { ReactComponent as RefreshIcon } from '../styles/icons/refresh-cw-svgrepo-com.svg';
import '../styles/navBar.css';

const ClearCacheButton = () => {
  
  const handleCacheUpdate = async () => {
    console.log('Cache cleared on manual refresh');
    const event = new CustomEvent('clearCache');
    window.dispatchEvent(event);
  };

  return (
    <button onClick={handleCacheUpdate} className="navBaricon-button">
      <RefreshIcon />
    </button>
  );
};

export default ClearCacheButton;