import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const SEVERITY_LABELS = ['None', 'Mild', 'Moderate', 'Severe'];

const PatientOverview = () => {
  const { patientId } = useParams();
  const userRole = localStorage.getItem('userRole');
  const [patientData, setPatientData] = useState({
    phoneNumber: '******',
    fullDoses: 0,
    partialDoses: 0,
    missedDoses: 0,
    adherenceRate: 0,
    severeAdrs: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPatientData = async (id) => {
    console.log(`Fetching patient data for ID: ${id}`);
    try {
      const response = await fetch(`https://api.tracktb.com/responses/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Raw API response:', data);
      return data;
    } catch (err) {
      console.error('Error fetching patient data:', err);
      setError(err.message);
      return [];
    }
  };

  useEffect(() => {
    const processPatientData = async () => {
      console.log('Processing patient data...');
      const responses = await fetchPatientData(patientId);

      if (responses.length === 0) {
        console.log('No responses received from API.');
        setLoading(false);
        return;
      }

      // Log raw responses
      console.log('Responses:', responses);

      // Calculate full, partial, and missed doses
      const fullDoses = responses.filter((r) => r.medicationtaken === 'All').length;
      const partialDoses = responses.filter((r) => r.medicationtaken === 'Some').length;
      const missedDoses = responses.filter((r) => r.medicationtaken === 'None').length;

      console.log(`Full doses: ${fullDoses}, Partial doses: ${partialDoses}, Missed doses: ${missedDoses}`);

      // Calculate adherence rate
      const totalDoses = fullDoses + partialDoses + missedDoses;
      const adherenceRate = totalDoses > 0 ? (fullDoses / totalDoses) * 100 : 0;

      console.log(`Total doses: ${totalDoses}, Adherence rate: ${adherenceRate.toFixed(2)}%`);

      // Extract severe ADRs
      const severeAdrs = responses
        .flatMap((r) =>
          Object.entries(r.adrs).filter(([_, severity]) => severity === 'Severe').map(([adr, _]) => adr)
        );

      console.log('Severe ADRs:', severeAdrs);

      setPatientData({
        phoneNumber: userRole === 'Primary Provider' ? responses[0]?.patientid || '******' : '******',
        fullDoses,
        partialDoses,
        missedDoses,
        adherenceRate: adherenceRate.toFixed(2),
        severeAdrs,
      });

      setLoading(false);
    };

    processPatientData();
  }, [patientId, userRole]);

  if (loading) {
    return <p>Loading data, please wait...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="patient-overview-container">
      <h2 className="overview-title">
        Patient {userRole === 'Primary Provider' ? <span className="bold-id">#{patientId}</span> : <span className="bold-id">******</span>} Overview
      </h2>
      <div className="overview-doses-container">
        <div className="doses-info">
          <p className="full-doses">{patientData.fullDoses} Full doses taken</p>
          <p className="partial-doses">{patientData.partialDoses} Partial doses taken</p>
          <p className="missed-doses">{patientData.missedDoses} Doses missed</p>
        </div>
        <div className="adherence-progress-bar">
          <CircularProgressbar
            value={patientData.adherenceRate}
            text={`${patientData.adherenceRate}%`}
            styles={buildStyles({
              pathColor: '#82ca9d',
              textColor: '#82ca9d',
            })}
          />
          <p>Adherence Rate</p>
        </div>
      </div>
      <h3 className="recent-adrs-title">Recent Severe ADRs:</h3>
      <ul className="adrs-list">
        {patientData.severeAdrs.length > 0 ? (
          patientData.severeAdrs.map((reaction, index) => (
            <li key={index}>{reaction}</li>
          ))
        ) : (
          <li>No severe ADRs reported</li>
        )}
      </ul>
    </div>
  );
};

export default PatientOverview;